import LoginCard from "@/components/Login/LoginCard";
import React, { useEffect, useState } from "react";
import en from "../../../locales/en";
import ar from "../../../locales/ar";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import UpdatePassword from "./UpdatePassword";
import { api } from "@/utils/Axios";

export default function OtpInputComponent() {
  const router = useRouter();
  const { locale } = router;
  const t = locale === "en" ? en : ar;
  const phone = useSelector((state) => state.phone.phone);
  const [otpResEncoded, setOtpResEncoded] = useState(null);

  const [otp, setOtp] = useState(null);
  const [UPdatePass, setUpdatePass] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [verificationToken, SetverificationToken] = useState("");
  const SendOtp = async (event) => {
    try {
      const Body = {
        phone: `+2${phone}`,
        gateway: "1",
      };

      const response = await api.post(`auth/sendOtp`, Body);

      if (response.data.statusCode === 200 && response.data) {
        setOtpResEncoded(response.data.data?.otpResEncoded);
      }
    } catch (error) {
      if (error.response?.status === 400) {
        setErrorMsg(error.response.data.message);
      }
    }
  };
  const VerifyOtp = async (event) => {
    event.preventDefault();
    try {
      const Body = {
        phone: `+2${phone}`,
        otpResEncoded,
        code: otp,
      };
      const response = await api.post(`auth/verifyOtp`, Body);

      if (response.status === 200) {
        SetverificationToken(response.data.data.verificationToken);
        setUpdatePass(true);
      }
    } catch (error) {
      // if (error.response.statusCode === 400) {
      setErrorMsg(t.Login.OTPNotVerfy);
      // }
    }
  };

  useEffect(() => {
    SendOtp();
  }, []);
  return (
    <>
      {UPdatePass ? (
        <UpdatePassword verificationToken={verificationToken} />
      ) : (
        <LoginCard
          otp={otp}
          errorMsg={errorMsg}
          setOtp={setOtp}
          SendOtp={SendOtp}
          handleSubmit={VerifyOtp}
          setErrorMsg={setErrorMsg}
          Title2={`${t.Login.OTPTitle.slice(
            0,
            t.Login.OTPTitle.indexOf("،")
          )} ${phone.slice(-3)} ${t.Login.OTPTitle.split("،")[1]} . `}
          type="otp"
        />
      )}
    </>
  );
}
