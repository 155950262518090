import { toast } from "react-toastify";
import store, { ContractAction, userAction } from "../../src/Redux/store";
import { EnvKey } from "./const";
import jsonwebtoken from "jsonwebtoken";
const { api } = require("./Axios");

export const FetchUserData = async (id, SetLoading, setData) => {
  SetLoading(true);
  try {
    const response = await api.get(`/users/${id}`);
    if (response.status === 200 && response.data) {
      if (setData) {
        setData(response.data);
      }

      store.dispatch(userAction.update({ key: "id", val: response.data.id }));
      store.dispatch(
        userAction.update({
          key: "activationStatus",
          val: response.data.activationStatus,
        })
      );
      store.dispatch(
        userAction.update({ key: "avatar", val: response.data.avatar })
      );
      store.dispatch(
        userAction.update({ key: "email", val: response.data.email })
      );
      store.dispatch(
        userAction.update({ key: "phone", val: response.data.phone })
      );

      store.dispatch(
        userAction.update({
          key: "isEligible",
          val: response.data.isEligible,
        })
      );
      store.dispatch(
        userAction.update({
          key: "name",
          val: response.data.name,
        })
      );
      store.dispatch(
        userAction.update({
          key: "nationalId",
          val: response.data.nationalId,
        })
      );
      store.dispatch(
        userAction.update({
          key: "refContact",
          val: response.data.refContact,
        })
      );
      store.dispatch(
        userAction.update({
          key: "isEligible",
          val: response.data.secondPhone,
        })
      );
      SetLoading(false);
    }
  } catch (error) {
    SetLoading(false);
  }
};
export const FetchUserContract = async (
  id,
  SetLoading,
  SetContractData,
  setErrMsg,
  t
) => {
  try {
    SetLoading(true);
    setErrMsg("");
    const ConREs = await api.get(`/users/${id}/contracts`);
    if (ConREs.status === 200 && ConREs.data) {
      SetContractData(ConREs.data);
      store.dispatch(
        ContractAction.update({ key: "Details", val: ConREs.data })
      );
      SetLoading(false);
    }
  } catch (error) {
    setErrMsg(t.user_installements.PreviousDealing.nodataerr);
    SetLoading(false);
  }
};

export const Update = async (userData, Data) => {
  try {
    let SessionData = jsonwebtoken.verify(
      localStorage.getItem("UT_UI"),
      EnvKey.secretKey
    );
    let UserID = SessionData.id;
    const Body = {
      name: userData.name ? userData.name : Data.name,
      email: userData.email ? userData.email : Data.email,
      avatar: userData.ImageSrc ? userData.ImageSrc : Data.avatar,
      secondPhoneL: userData.secondPhone
        ? userData.secondPhone
        : Data.secondPhone,
      refContac: userData.refContac ? userData.refContac : Data.refContac,
    };
    store.dispatch(userAction.update({ key: "name", val: Body.name }));
    store.dispatch(userAction.update({ key: "avatar", val: Body.avatar }));
    store.dispatch(userAction.update({ key: "email", val: Body.email }));
    store.dispatch(
      userAction.update({ key: "refContac", val: Body.refContac })
    );
    store.dispatch(
      userAction.update({ key: "secondPhone", val: Body.secondPhoneL })
    );

    const response = await api.put(`users/${UserID}`, Body);
    if (response.data.isSuccessful) {
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  } catch (error) {
    toast.error(error.data.message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
};
