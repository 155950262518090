import { id } from "date-fns/locale";
import * as yup from "yup";
// const phoneRegExp =
const phoneRegExp = /^(010|011|012|015)\d{8}$/;
const phoneRegExp2 = /^(10|11|12|15)\d{8}$/;
const emailRegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
const anotherCountryPhoneRegExp = /^(?:50|51|52|54|55|56|58|2|3|4|6|7|9)\d{7}$/;
const nationaId =
  /^([1-9]{1})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})[0-9]{3}([0-9]{1})[0-9]{1}$/;
  const amountRegExp = /^\d+$/;
  const fileSchema = yup
  .mixed()
  .test(
    "fileSize",
    "should be less than 5MB.",
    (value) => !value || (value && value.size <= 5 * 1024 * 1024)
  )
  .test(
    "fileType",
    "Invalid file type. Please upload the correct file type",
    (value) =>
      !value ||
      (value && value.type === "application/pdf") ||
      value?.type.startsWith("image/")
  );

export const EnUpdatePasswprdSchema = yup.object().shape({
  Password: yup
    .string()
    .test(
      "password",
      "Password must contain at least 8 characters",
      (value) => value.length >= 8
    )
    .test(
      "password",
      "Password must contain at least one uppercase letter",
      (value) => /[A-Z]/.test(value)
    )
    .test(
      "password",
      "Password must contain at least one lowercase letter",
      (value) => /[a-z]/.test(value)
    )
    .test("password", "Password must contain at least one digit", (value) =>
      /\d/.test(value)
    )
    .test(
      "password",
      "Password must contain at least one special character",
      (value) => /[!@#$%^&*()_\-+=\[\]{};':"\\|,.<>\/?~]/.test(value)
    )
    .required("Required"),
  confirmPassword: yup
    .string()
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.Password === value;
    })
    .required("Required"),
});
export const EnCampaignSchema = yup.object().shape({
  name: yup
    .string()
    .min(3, "Username must be at least 3 characters long")
    .required("Required"),

  email: yup
    .string()
    .email()
    .matches(emailRegExp, "Please enter a valid email")
    .required("Required"),
  mobilePhone: yup
    .string()
    .matches(
      phoneRegExp,
      "Phone number must start with (010/011/012/015) and at least 11 digits"
    )
    .min(11, "Must be exactly 11 digits")
    .max(11, "Must be exactly 11 digits")
    .required("Required"),
  products: yup.string().required("Required"),
});
// const baseSchema = (flag)=>yup.object().shape({
//   name: yup
//     .string()
//     .min(3, "Username must be at least 3 characters long")
//     .required("Required"),
//   email: yup
//     .string()
//     .matches(emailRegExp, "Please enter a valid email")
//     .required("Required"),
//   mobilePhone: yup
//     .string()
//     .matches(
//       phoneRegExp,
//       "Phone number must start with (010/011/012/015) directly"
//     )
//     .min(11, "Must be exactly 11 digits")
//     .max(11, "Must be exactly 11 digits")
//     .required("Required"),
//   governance: yup.string().required("Required"),

// });
const baseSchema = (flag, country) => {
  let schema = yup.object().shape({
    name: yup
      .string()
      .min(3, "Username must be at least 3 characters long")
      .required("Required"),
    email: yup
      .string()
      .matches(emailRegExp, "Please enter a valid email")
      .required("Required"),
    governance: yup.string().required("Required"),
  });

  if (flag === "product") {
    if (country === "Egypt") {
      schema = schema.shape({
        mobilePhone: yup
          .string()
          .matches(
            phoneRegExp2,
            "Phone number must start with (10/11/12/15) and at least 10 digits"
          )
          .min(10, "Must be exactly 10 digits")
          .max(10, "Must be exactly 10 digits")
          .required("Required"),
      });
    } else if (country === "United Arab Emirates") {
      schema = schema.shape({
        mobilePhone: yup
          .string()
          .min(9, "Must be exactly 9 digits")
          .max(9, "Must be exactly 9 digits")
          .matches(
            anotherCountryPhoneRegExp,
            "Phone number must start with (50/51/52/54/55/56/58) directly"
          )
          .required("Required"),
        passportUrl: fileSchema.required("Required"),
        workPermitUrl: fileSchema.notRequired(),
        hrLetter: fileSchema.notRequired(),
        bankStatementUrl: fileSchema.notRequired(),
        EgyptAddress: yup.string().required("Required"),
      });
    } else {
      schema = schema.shape({
        mobilePhone: yup
          .string()
          .matches(
            phoneRegExp,
            "Phone number must start with (010/011/012/015) and at least 11 digits"
          )
          .min(11, "Must be exactly 11 digits")
          .max(11, "Must be exactly 11 digits")
          .required("Required"),
      });
    }
  } else {
    schema = schema.shape({
      mobilePhone: yup
        .string()
        .matches(
          phoneRegExp,
          "Phone number must start with (010/011/012/015) and at least 11 digits"
        )
        .min(11, "Must be exactly 11 digits")
        .max(11, "Must be exactly 11 digits")
        .required("Required"),
    });
  }
  return schema;
};

export const EnSchema = (flag, country) => {
  const schema = baseSchema(flag, country);
  return schema;
};
export const EnSchemaA = (flag, country) => {
  const schema = baseSchema(flag, country).concat(
    yup.object().shape({
      category: yup.string().required("Required"),
      amount: yup.string().matches(amountRegExp,"Only numbers are allowed").required("Required"),
    })
  );
  return schema;
};

export const EnSchemaB = (flag, country) => {
  const schema = baseSchema(flag, country).concat(
    yup.object().shape({
      products: yup.string().required("Required"),
    })
  );
  return schema;
};

export const EnMortgageSchema = (flag, country) => {
  const schema = baseSchema(flag, country).concat(
    yup.object().shape({
      mortgageType: yup.string().required("Required"),
    })
  );
  return schema;
};
export const EnSchema_Application = (flag, country) => {
  const schema = baseSchema(flag, country).concat(
    yup.object().shape({
      job: yup.string().required("Required"),
      resumeUrl: yup.string().required("Required"),
      governance: yup.string().nullable().notRequired(),
      governorate: yup.string().required("Required"),
    })
  );

  return schema;
};
// export const EnSchemaA = baseSchema.concat(
//   yup.object().shape({
//     category: yup.string().required("Required"),
//     amount: yup.string().required("Required"),
//   })
// );

// export const EnSchemaB = baseSchema.concat(
//   yup.object().shape({
//     products: yup.string().required("Required"),
//   })
// );
// export const EnMortgageSchema = baseSchema.concat(
//   yup.object().shape({
//     mortgageType: yup.string().required("Required"),
//   })
// );
// export const EnSchema_Application = baseSchema.concat(
//   yup.object().shape({
//     job: yup.string().required("Required"),
//     resumeUrl: yup.string().required("Required"),
//   })
// );
