import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useRouter } from "next/router";
import en from "../../../locales/en";
import ar from "../../../locales/ar";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OTPInput from "react-otp-input";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { phoneActions } from "@/Redux/store";
import { useDispatch } from "react-redux";
import { colors } from "@/utils/const";
import { api } from "@/utils/Axios";
import { toast } from "react-toastify";

export default function LoginCard({
  InPut,
  setInPut,
  Phone,
  setPhone,
  PhoneError,
  setPhoneError,
  Password,
  setPassword,
  PasswordError,
  InPutError,
  errorMsg,
  handleSubmit,
  Title2,
  Label,
  type,
  resetPasswordVisible,
  setResetPasswordVisible,
  otp,
  setOtp,
  SendOtp,
  setErrorMsg,
  Next,
}) {
  const styles = {
    helperText: (theme) => ({
      color: "#d32f2f",
      fontSize: "0.75rem",
      mb: type !== "otp" && type !== "password" ? 3 : 1,
      mt: type !== "otp" ? 0 : -2,
    }),
  };
  const router = useRouter();
  const { locale } = router;
  const [showPassword, setShowPassword] = useState(false);
  const [ValidationError, setValidationError] = useState(false);
  const [resetPass, setResetPass] = useState("");
  const [touched, setTouched] = useState(false);
  const t = locale === "en" ? en : ar;
  const [timeLeft, setTimeLeft] = useState(60);
  const [Progress, setProgress] = useState(false);
  const [Resend, setResend] = useState(false);
  const isPasswordValid = Password?.length >= 8;
  const dispatch = useDispatch();
  const children = ({ remainingTime }) => (
    <div role="timer" aria-live="assertive">
      {remainingTime}
    </div>
  );
  useEffect(() => {
    if (timeLeft >= 0) {
      const interval = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
        setProgress(true);
        setResend(false);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    } else {
      setProgress(false);
      setResend(true);
    }
  }, [timeLeft]);
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const isPhoneInput = type === "phone";
  const isPasswordInput = type === "password";
  const isOtpInput = type === "otp";
  const isPhoneNumberValid = (input) => {
    return /^(010|011|012|015)\d{8}$/.test(input);
  };

  const EmptyInput = (input) => {
    return input.length === 0;
  };
  const [timer, setTimer] = useState(null);
  const handlePhoneChange = (e) => {
    clearTimeout(timer);
    const PhoneValue = e.target.value;
    setPhone(PhoneValue);
    dispatch(phoneActions.update(PhoneValue));
    const newTimer = setTimeout(() => {
      setErrorMsg("");
      setValidationError("");
      setResetPass("");
      setPhoneError(false);

      setTouched(true);
      const isValid = isPhoneNumberValid(PhoneValue) || EmptyInput(PhoneValue);
      setValidationError(!isValid);
    }, 1500);
    setTimer(newTimer);
  };
  const handlePasswordChange = (e) => {
    clearTimeout(timer);
    const passwordValue = e.target.value;
    setPassword(passwordValue);
    setErrorMsg("");
  };

  //check user exist or not
  const checkUserExistence = async (phone) => {
    try {
      const Body = {
        phone: `+2${phone}`,
      };

      const response = await api.post(`/auth/password/checkExistence`, Body);

      if (response.status === 200 && response.data) {
        setResetPasswordVisible(true);
      }
    } catch (error) {
      toast.error(`${t.Login.CheckUserExistence}`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleResetPassword = () => {
    if (!Phone || !isPhoneNumberValid(Phone)) {
      setPhoneError(true);
      setResetPass(t.Login.resetError);
    } else {
      setPhoneError(false);
      checkUserExistence(Phone);
      setResetPass("");
      setPhone("");
      setPassword("");
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "center",
        backgroundColor: "whitesmoke",
        borderRadius: "20px",
        width: { xs: "380px", md: "500px" },
        height: "450px",
        padding: "20px",
      }}
    >
      <Box>
        <Typography
          component="h1"
          variant="h5"
          sx={{ color: "black", fontWeight: "bold", marginBottom: "20px" }}
        >
          {t.Login.Login}
        </Typography>
        <Typography
          component="h1"
          sx={{ color: "black", marginBottom: "20px", fontSize: "14px" }}
        >
          {type === "otp" ? Title2 : null}
        </Typography>
      </Box>
      <Box
        sx={{ width: "100%" }}
        component="form"
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        {type !== "otp" ? (
          <>
            <TextField
              margin="normal"
              sx={{
                width: "100%",
                // mb: errorMsg || ValidationError || resetPass ? 5 : 4,
              }}
              // required
              placeholder={t.Login.PhoneLabel}
              name="username"
              autoComplete="new-username"
              autoFocus
              error={PhoneError}
              value={Phone}
              type="text"
              onChange={handlePhoneChange}
              inputProps={{
                maxLength: 11,
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              // not allow copy paste action
              onPaste={(e) => {
                e.preventDefault();
              }}
            />
            {(ValidationError && touched && Phone.length >= 7) || resetPass ? (
              <Box sx={{ mb: 4 }}>
                <Box sx={styles.helperText}>
                  {resetPass || t.Login.PhoneError}
                </Box>
              </Box>
            ) : null}
            <TextField
              margin="normal"
              sx={{
                width: "100%",
                //  mb: errorMsg || ValidationError ? 5 : 5
              }}
              // required
              name="password" 
              autoComplete="new-password"
              placeholder={t.Login.PassLabel}
              error={PasswordError}
              value={Password}
              type={showPassword ? "text" : "password"}
              onChange={handlePasswordChange}
              // inputProps={isPhoneInput ? { maxLength: 11 } : {}}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onPaste={(e) => {
                e.preventDefault();
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: {xs:"100%",sm:"90%",md:"100%"},
                flexWrap:{xs:"wrap",sm:"nowrap"}
              }}
            >
              {errorMsg && (
                <Box>
                  <Box sx={styles.helperText}>{errorMsg}</Box>
                </Box>
              )}
              {!resetPasswordVisible && type !== "otp" && (
                <Button
                  aria-label="Reset password button"
                  onClick={handleResetPassword}
                  sx={{
                    whiteSpace: "nowrap",
                    p: 0,
                    ml: locale === "en" ? "auto" : 0,
                    mr: locale === "ar" ? "auto" : 0,
                    mb: 5,
                    fontSize: { xs: "10px", lg: "12px !important" },
                    "&:hover ": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  {t.Login.ResetPassword}
                </Button>
              )}
            </Box>
          </>
        ) : (
          <div dir="ltr">
            <OTPInput
              value={otp}
              onChange={setOtp}
              numInputs={5}
              separator={<span>&nbsp;</span>}
              placeholder=""
              inputType="tel"
              renderInput={(inputProps, index) => (
                <input
                  className="hiiiii"
                  {...inputProps}
                  maxLength={1}
                  key={index}
                  style={{
                    width: "50px",
                    height: "50px",
                    fontSize: "18px",
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    margin: "14px auto ",
                    textAlign: "center",
                    outline: "none",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              )}
            />
            {errorMsg && (
              <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
                {" "}
                <Box sx={styles.helperText}>{errorMsg}</Box>
              </Box>
            )}
            {!Progress && Resend ? (
              <Button
                aria-label="Resend otp button"
                onClick={() => {
                  setProgress(true);
                  setErrorMsg("");
                  setResend(false);
                  setTimeLeft(60);
                  SendOtp();
                }}
                // fullWidth
                sx={{
                  // mt: -4,
                  display: "flex",
                  justifyContent: "center",
                  m: "auto",
                  mb: 2,
                  "&:hover ": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                {t.Login.ResendOtp}
              </Button>
            ) : (
              <>
                <Box
                  sx={{
                    // mt: -3,
                    display: "flex",
                    justifyContent: "center",
                    mb: 2,
                  }}
                >
                  <CountdownCircleTimer
                    isPlaying
                    duration={60}
                    children={children}
                    colors={colors.orange}
                    size={35}
                    strokeWidth={3}
                  />
                </Box>
              </>
            )}
          </div>
        )}

        <Button
          aria-label=" sumbit button in Login form"
          type="submit"
          fullWidth
          variant="contained"
          disabled={
            (!Password && type !== "otp") ||
            (Password && (!isPasswordValid || errorMsg) && type !== "otp") ||
            (!Phone && type !== "otp") ||
            (Phone && ValidationError && type !== "otp") ||
            (type === "otp" && (!otp || otp.length < 5))
          }
          sx={{
            width: "100%",
            mb: 5,
            py: { md: 1.5 },
            fontWeight: "bold",
            backgroundColor: "#071B5F",
            color: "#fff",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            "&:hover": {
              backgroundColor: "#071B5F",
              color: "#fff",
              boxShadow: "inherit",
            },
          }}
        >
          {!Next && type === "password" ? (
            <CircularProgress
              size={25}
              sx={{ color: "#fff" }}
            ></CircularProgress>
          ) : (
            t.Login.Next
          )}
        </Button>
      </Box>
    </Box>
  );
}
