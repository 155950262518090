import React, { useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useRouter } from "next/router";
import en from "../../../locales/en";
import ar from "../../../locales/ar";
import { EnUpdatePasswprdSchema } from "../../utils/en_schema.js";
import { ArUpdatePasswprdSchema } from "../../utils/ar_schema.js";
import { styles } from "../form";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import Log from "src/pages/login/index.jsx";
import { api } from "@/utils/Axios";
import { toast } from "react-toastify";
export default function UpdatePassword({ verificationToken }) {
  const router = useRouter();
  const { locale } = router;
  const t = locale === "en" ? en : ar;
  const phone = useSelector((state) => state.phone.phone);

  const [showPassword, setShowPassword] = React.useState(false);
  const [showCoPassword, setCoShowPassword] = React.useState(false);

  const [Done, setDone] = React.useState(false);
  const [Expired, setExpired] = React.useState(false);

  const [Login, setLogin] = React.useState(false);
  const [OTP, setOTP] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleCoPasswordVisibility = () => {
    setCoShowPassword(!showCoPassword);
  };
  const onSubmit = async (values, actions) => {
    try {
      setLoading(true);
      const Body = {
        phone: `+2${phone}`,
        password: values.Password,
        verificationToken,
      };

      const response = await api.post(`auth/updatePassword`, Body);

      if (response.status === 200 && response.data) {
        actions.resetForm({ values: null });
        setDone(true);
        setLogin(true);
      }
    } catch (error) {
      actions.resetForm({ values: null });
      toast.error(`${t.Login.VerificationToken} `, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setExpired(true);
      setOTP(true);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  let ArSchemaTest = ArUpdatePasswprdSchema;
  let EnSchemaTest = EnUpdatePasswprdSchema;
  let initialValuesTest = {
    Password: "",
    confirmPassword: "",
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    isSubmitting,
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: initialValuesTest,
    validationSchema: locale === "en" ? EnSchemaTest : ArSchemaTest,
    onSubmit,
  });

  useEffect(() => {
    return () => {
      setExpired(false);
      setOTP(false);
      setExpired(true);
      setOTP(true);
    };
  }, []);

  return Login || OTP ? (
    <Log />
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        backgroundColor: "whitesmoke",
        borderRadius: "20px",
        width: { xs: "380px", lg: "500px" },
        height: errors || Done ? "350px" : "330px",
        padding: "20px",
      }}
    >
      <Typography
        component="h1"
        variant="h5"
        sx={{ color: "black", fontWeight: "bold", marginBottom: "20px" }}
      >
        {t.Login.UpdatePassword}
      </Typography>

      <Box sx={{ width: "100%" }} component="form" onSubmit={handleSubmit}>
        <TextField
          margin="normal"
          sx={{ width: "100%", mb: 1 }}
          // required
          id="password"
          placeholder={t.Login.PassLabel}
          name="Password"
          //   autoFocus
          error={touched.Password && errors.Password}
          //   error={!!errors.Password}
          value={values.Password}
          type={showPassword ? "text" : "password"}
          onChange={handleChange}
          onBlur={handleBlur}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box sx={styles.helperText}>{touched.Password && errors.Password}</Box>

        <TextField
          margin="normal"
          sx={{ width: "100%", mb: errors.confirmPassword ? 1 : 5 }}
          // required
          id="confirmPassword"
          placeholder={t.Login.ConfirmLabel}
          name="confirmPassword"
          error={touched.confirmPassword && errors.confirmPassword}
          value={values.confirmPassword}
          type={showCoPassword ? "text" : "password"}
          onChange={handleChange}
          onBlur={handleBlur}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleToggleCoPasswordVisibility}
                  edge="end"
                >
                  {showCoPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box sx={styles.helperText}>
          {touched.confirmPassword && errors.confirmPassword}
        </Box>
        <Button
          aria-label=" sumbit button in update password form"
          type="submit"
          fullWidth
          variant="contained"
          disabled={
            isSubmitting ||
            Object.keys(errors).length > 0 ||
            !values.Password ||
            !values.confirmPassword
          }
          sx={{
            width: "100%",
            mt: Done ? 1 : null,
            fontWeight: "bold",
            backgroundColor: "#071B5F",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#071B5F",
              color: "#fff",
            },
          }}
        >
          {loading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            t.Login.UpdatePassword
          )}
        </Button>
      </Box>
    </Box>
  );
}
