import React, { useEffect, useState } from "react";
import axios from "axios";
import en from "../../../locales/en";
import ar from "../../../locales/ar";
import { useRouter } from "next/router";
import LoginCard from "@/components/Login/LoginCard";
import { useDispatch, useSelector } from "react-redux";
import { APIS, EnvKey } from "../../utils/const";
import { api } from "@/utils/Axios";
import jsonwebtoken from "jsonwebtoken";
import { phoneActions, userAction } from "@/Redux/store";
import Link from "next/link";
import { FetchUserData } from "@/utils/Fetch_userDataFn";
import OtpInputComponent from "@/components/Login/Otp";
import { Box, Typography } from "@mui/material";
import Head from "next/head";
import PageHead from "@/components/PageHead";

function Login({ setauthenticated }) {
  const [Phone, setPhone] = useState("");
  const [Password, setPassword] = useState("");
  const [PhoneError, setPhoneError] = useState(false);
  const [PasswordError, setPasswordError] = useState(false);
  const [resetPasswordVisible, setResetPasswordVisible] = useState(false);
  const router = useRouter();
  const { locale } = router;
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const t = locale === "en" ? en : ar;
  const [Contract, SetContract] = useState(false);
  const [Loading, SetLoading] = useState(true);
  const [Next, SetNext] = useState(true);
  const [data, SetData] = useState("");
  const [isLogin, setLogin] = useState(true);

  // const [InPut, setInPut] = useState("");
  // const [InPutError, setInPutError] = useState(false);
  // const { locale } = router;
  // const t = locale === "en" ? en : ar;
  // const phone = useSelector((state) => state.phone.phone);
  const dispatch = useDispatch();
  let timeoutId;
  useEffect(() => {
    if (localStorage.getItem("UT_UI")) {
      setLogin(true);
      router.push("/contract");
    } else {
      setLogin(false);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const handleSubmit = async (event) => {
    SetNext(false);
    event.preventDefault();
    try {
      const Body = {
        phone: `+2${Phone}`,
        password: Password,
      };
      const response = await api.post(`/auth/login`, Body);

      if (response.status === 201 && response.data) {
        // get loyalty points
        response.data?.nationalId &&api.get(`/wallets/${response.data.userId}`).then((res) => {
          dispatch(userAction.update({ key: "wallet", val: res.data }));
        });
        // ------------------
        dispatch(userAction.update({ key: "id", val: response.data.userId }));
        const userData = jsonwebtoken.sign(
          {
            token: response.data.token.accessToken,
            refreshToken: response.data.token.refreshToken,
            phone: Body.phone,
            id: response.data.userId,
          },
          EnvKey.secretKey
        );
        localStorage.setItem("UT_UI", userData);
        setErrorMsg("");
        FetchUserData(response.data.userId, SetLoading, SetData);
        timeoutId = setTimeout(() => {
          SetNext(true);
          router.push("/contract");
        }, 1500);
      }
    } catch (error) {
      SetNext(true);
      setError(true);
      setErrorMsg(t.Login.ErrorMessPass);
      if (error.response?.data?.statusCode === 403) {
        setErrorMsg(error.response.data.message); //accound Locked
        setErrorMsg("try agin later account is Locked");
      }
      if (error.response && error.response.data.statusCode === 500) {
        setErrorMsg(t.Login.ErrorMessPass);
      }
    }
  };
  return (
    <>
      {!isLogin && (
        <>
          <PageHead
            title={t.pages_main_tags.login.title}
            description={t.pages_main_tags.login.meta_desc}
          />
          <Box
            sx={{
              backgroundColor: "#EBEBED",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "74vh",
            }}
          >
            <Typography
              sx={{
                visibility: "hidden",
                width: "0px",
                height: "0px",
                fontSize: "10px",
              }}
              variant="h1"
            >
              {t.pages_main_tags.login.h1}
            </Typography>
            <Typography
              sx={{
                visibility: "hidden",
                width: "0px",
                height: "0px",
                fontSize: "1px",
              }}
              variant="h2"
            >
              {t.pages_main_tags.login.h2}
            </Typography>
            {resetPasswordVisible ? (
              <OtpInputComponent />
            ) : (
              <LoginCard
                Phone={Phone}
                setPhone={setPhone}
                PhoneError={PhoneError}
                setPhoneError={setPhoneError}
                Password={Password}
                setPassword={setPassword}
                PasswordError={PasswordError}
                errorMsg={errorMsg}
                setErrorMsg={setErrorMsg}
                handleSubmit={handleSubmit}
                Title2={t.Login.PassTitle}
                Label={t.Login.PassLabel}
                resetPasswordVisible={resetPasswordVisible}
                setResetPasswordVisible={setResetPasswordVisible}
                Next={Next}
              />
            )}
          </Box>
        </>
      )}
    </>
  );
}
export default Login;
