import * as yup from "yup";
const phoneRegExp = /^(010|011|012|015)\d{8}$/;
const emailRegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
const phoneRegExp2 = /^(10|11|12|15)\d{8}$/;
const anotherCountryPhoneRegExp = /^(?:50|51|52|54|55|56|58|2|3|4|6|7|9)\d{7}$/;
const amountRegExp = /^\d+$/;
const fileSchema = yup
  .mixed()
  .test(
    "fileSize",
    "يجب أن يكون أقل من 5 ميغابايت",
    (value) => !value || (value && value.size <= 5 * 1024 * 1024)
  )
  .test(
    "fileType",
    "نوع الملف غير صحيح. يرجى تحميل نوع الملف الصحيح",
    (value) =>
      !value ||
      (value && value.type === "application/pdf") ||
      value?.type.startsWith("image/")
  );

export const ArUpdatePasswprdSchema = yup.object().shape({
  Password: yup
    .string()
    .test(
      "password",
      "يجب أن تحتوي كلمة المرور على 8 أحرف على الأقل",
      (value) => value.length >= 8
    )
    .test(
      "password",
      "يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل",
      (value) => /[A-Z]/.test(value)
    )
    .test(
      "password",
      "يجب أن تحتوي كلمة المرور على حرف صغير واحد على الأقل",
      (value) => /[a-z]/.test(value)
    )
    .test(
      "password",
      "يجب أن تحتوي كلمة المرور على رقم واحد على الأقل",
      (value) => /\d/.test(value)
    )
    .test(
      "password",
      "يجب أن تحتوي كلمة المرور على حرف خاص واحد على الأقل",
      (value) => /[!@#$%^&*()_\-+=\[\]{};':"\\|,.<>\/?~]/.test(value)
    )
    .required("مطلوب"),
  confirmPassword: yup
    .string()
    .test("passwords-match", "يجب أن تتطابق كلمتا المرور", function (value) {
      return this.parent.Password === value;
    })
    .required("مطلوب"),
});
// mobilePhone: yup
//   .string()
//   .matches(phoneRegExp, "رقم الهاتف يجب أن يبدا بـ (010/011/012/015) مباشرة")
//   .min(11, "يجب أن يكون 11 رقم")
//   .max(11, "يجب أن يكون 11 رقم")
//   .required("اجباري"),
const baseSchema = (flag, country) => {
  let schema = yup.object().shape({
    name: yup
      .string()
      .min(3, "الإسم يجب ان يكون على الأقل 3 حروف")
      .required("اجباري"),
    email: yup
      .string()
      .matches(emailRegExp, "برجاء ادخال ايميل صحيح")
      .required("اجباري"),
    governance: yup.string().required("اجباري"),
  });
  if (flag === "product") {
    if (country === "مصر") {
      schema = schema.shape({
        mobilePhone: yup
          .string()
          .matches(
            phoneRegExp2,
            "رقم الهاتف يجب أن يبدأ بـ (10/11/12/15) وعلى الاقل 10 ارقام"
          )
          .min(10, "يجب أن يكون 10 رقم")
          .max(10, "يجب أن يكون 10 رقم")
          .required("اجباري"),
      });
      // mobilePhone: yup
      //   .string()
      //   .matches(phoneRegExp, "رقم الهاتف يجب أن يبدا بـ (010/011/012/015) مباشرة")
      //   .min(11, "يجب أن يكون 11 رقم")
      //   .max(11, "يجب أن يكون 11 رقم")
      //   .required("اجباري"),
    } else if (country === "الامارات") {
      schema = schema.shape({
        mobilePhone: yup
          .string()
          .min(9, "يجب أن يكون 9 رقم")
          .max(9, "يجب أن يكون 9 رقم")
          .matches(
            anotherCountryPhoneRegExp,
            "يجب أن يبدأ رقم الهاتف بـ (50/51/52/54/55/56/58) مباشرة"
          )
          .required("اجباري"),
        passportUrl: fileSchema.required("اجباري"),
        workPermitUrl: fileSchema.notRequired(),
        hrLetter: fileSchema.notRequired(),
        bankStatementUrl: fileSchema.notRequired(),
        EgyptAddress: yup.string().required("اجباري"),
      });
    } else {
      schema = schema.shape({
        mobilePhone: yup
          .string()
          .matches(
            phoneRegExp,
            "رقم الهاتف يجب أن يبدأ بـ (010/011/012/015) وعلى الاقل 11 رقم"
          )
          .min(11, "يجب أن يكون 11 رقم")
          .max(11, "يجب أن يكون 11 رقم")
          .required("اجباري"),
      });
    }
  } else {
    schema = schema.shape({
      mobilePhone: yup
        .string()
        .matches(
          phoneRegExp,
          "رقم الهاتف يجب أن يبدأ بـ (010/011/012/015) وعلى الاقل 11 رقم"
        )
        .min(11, "يجب أن يكون 11 رقم")
        .max(11, "يجب أن يكون 11 رقم")
        .required("اجباري"),
    });
  }
  return schema;
};
export const ArSchema = (flag, country) => {
  const schema = baseSchema(flag, country);
  return schema;
};
export const ArCampaignSchema = yup.object().shape({
  name: yup
    .string()
    .min(3, "الإسم يجب ان يكون على الأقل 3 حروف")
    .required("اجباري"),

  email: yup
    .string()
    // .email("برجاء ادخال ايميل صحيح")
    .matches(emailRegExp, "برجاء ادخال ايميل صحيح")
    .required("اجباري"),
  mobilePhone: yup
    .string()
    .matches(phoneRegExp, "رقم الهاتف يجب أن يبدا بـ (010/011/012/015) وعلى الاقل 11 رقم")
    .min(11, "يجب أن يكون 11 رقم")
    .max(11, "يجب أن يكون 11 رقم")
    .required("اجباري"),
  products: yup.string().required("اجباري"),
});
export const ArSchemaA = (flag, country) => {
  const schema = baseSchema(flag, country).concat(
    yup.object().shape({
      category: yup.string().required("اجباري"),
      // car_price: yup.number().positive().required("اجباري"),
      amount: yup.string().matches(amountRegExp,"يُسمح بالأرقام فقط").required("اجباري"),
    })
  );
  return schema;
};
export const ArSchemaB = (flag, country) => {
  const schema = baseSchema(flag, country).concat(
    yup.object().shape({
      products: yup.string().required("اجباري"),
    })
  );
  return schema;
};
export const ArMortgageSchema = (flag, country) => {
  const schema = baseSchema(flag, country).concat(
    yup.object().shape({
      mortgageType: yup.string().required("اجباري"),
    })
  );
  return schema;
};
export const ArSchema_Application = (flag, country) => {
  const schema = baseSchema(flag, country).concat(
    yup.object().shape({
      job: yup.string().required("اجباري"),
      resumeUrl: yup.string().required("اجباري"),
      governance: yup.string().nullable().notRequired(),
      governorate: yup.string().required("اجباري"),
    })
  );
  return schema;
};
